.thumbnail-image {
    width: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

.inline-block {
    display: inline-block;
}

#main {
    margin-top: 62px;
}

.lt {
    text-decoration: line-through;
    font-style: normal;
    font-weight: bold;
    color: red;
}